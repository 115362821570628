<template>
  <nav
    id="primaryNav"
    class="flex items-center bg-madera-100 min-h-11 px-8 xl:px-10 2xl:px-10 filter shadow"
  >
    <ul v-if="navMainLinks" class="flex flex-wrap gap-6 2xl:gap-8 items-center">
      <li
        v-for="link in navMainLinks"
        :key="link.dest"
        class="inline-flex justify-center items-center"
      >
        <BaseLink
          class="hover:underline hover:font-bold text-lg p-2 text-center"
          :to="link.dest"
          >{{ link.title }}</BaseLink
        >
      </li>

      <li class="flex-grow flex justify-end">
        <a
          href="https://www.linkedin.com/company/precision-wordcraft"
          class="inline-flex h-11 p-4"
        >
          <img src="../assets/images/linkedin.svg" alt="LinkedIn" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useStoryblokStore } from '../store/useStoryblok'

const global = useStoryblokStore()

const { navMainLinks } = storeToRefs(global)
</script>
