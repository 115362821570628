<template>
  <div class="sm:hidden border border-gris-300">
    <div v-for="(lang, index) in panelLangs" :key="index">
      <TranslationReferencePanelLanguage
        :id="`panel-title-lang-${lang.abbrev}`"
        class="border border-gris-300"
        :lang="lang.name"
      />

      <TranslationReferencePanelArticle
        :aria-labelledby="`lang-panel-${lang.abbrev}`"
        :passage="passageWithLang(lang.abbrev)"
        :lang="lang.name"
        class="px-4 py-2 border border-gris-300"
      />
    </div>
  </div>

  <div class="hidden sm:block border border-gris-300">
    <div class="flex" aria-hidden="true">
      <TranslationReferencePanelLanguage
        v-for="(lang, index) in panelLangs"
        :id="`panel-title-lang-${lang.abbrev}`"
        :key="index"
        class="hidden sm:inline w-1/3 border border-gris-300"
        :lang="lang.name"
      />
    </div>

    <div class="h-119 grid grid-cols-3 grid-rows-1 bg-white">
      <div
        v-for="(lang, index) in panelLangs"
        :key="index"
        class="border border-gris-300 row-start-1 col-span-1"
        :style="`grid-column-start: ${index + 1};`"
      ></div>

      <div class="row-start-1 col-start-1 col-span-3 flex overflow-y-scroll">
        <TranslationReferencePanelArticle
          v-for="(lang, index) in panelLangs"
          :key="index"
          :aria-labelledby="`lang-panel-${lang.abbrev}`"
          :passage="passageWithLang(lang.abbrev)"
          :lang="lang.name"
          class="sm:w-1/3 px-4 py-2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import TranslationReferencePanelLanguage from './TranslationReferencePanelLanguage.vue'
import TranslationReferencePanelArticle from './TranslationReferencePanelArticle.vue'
import { useStoryblokStore } from '../store/useStoryblok'
const global = useStoryblokStore()

const props = defineProps({
  panel: {
    type: Object,
    default: () => {},
  },
})

const panelLangs = [
  { abbrev: 'es', name: global.settings.translationLanguageEs },
  { abbrev: 'ah', name: global.settings.translationLanguageAh },
  { abbrev: 'en', name: global.settings.translationLanguageEn },
]

const passageWithLang = (lang) => {
  return props.panel[`passage_${lang}`]
}
</script>
