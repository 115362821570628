<template>
  <div class="border-t border-gray mt-4 sm:mt-12 pt-4 pb-12 sm:pb-16 sm:pt-12">
    <h2>{{ blok.title }}</h2>

    <!-- Bio -->
    <div
      class="flex flex-col gap-2 sm:gap-8 sm:flex-row justify-start mt-4 sm:mt-6"
    >
      <div class="flex flex-row items-center sm:items-start sm:flex-col">
        <SquareImage
          v-if="blok.headshot"
          class="w-49"
          :src="blok.headshot.filename"
          :alt="blok.headshot.alt"
        />
        <a
          v-if="blok.linkedin.url"
          :href="blok.linkedin.url"
          class="m-auto sm:m-0 sm:mt-2"
        >
          <img class="w-11 p-4" src="../assets/images/linkedin.svg" alt />
          <span class="sr-only">{{ global.settings.socialLinkedinText }}</span>
        </a>
      </div>
      <div>
        <h3>{{ blok.name }}</h3>
        <div class="mt-1 parsed" v-html="parse(blok.biography)"></div>
      </div>
    </div>

    <!-- Affiliations -->
    <ul
      v-if="blok.organization"
      class="mt-6 flex flex-wrap max-w-37 gap-6 sm:max-w-137 sm:gap-12 xl:max-w-236 m-auto justify-center"
    >
      <li v-for="org in blok.organization" :key="org._uid" class="mx-6">
        <SquareImage
          v-if="org.logo.filename"
          :src="org.logo.filename"
          :alt="org.logo.alt"
          class="w-37 mx-auto"
        />
        <BaseLink
          v-if="org.link.url || org.link.cached_url"
          class="pwc-ext-link hover:underline"
          :href="org.link.url || null"
          :to="org.link.cached_url || null"
        >
          {{ org.link_text }}
        </BaseLink>
      </li>
    </ul>
  </div>
</template>

<script setup>
import SquareImage from './SquareImage.vue'
import { useStoryblokStore } from '../store/useStoryblok'
import { parse } from 'marked'

const global = useStoryblokStore()

defineProps({
  blok: {
    type: Object,
    required: true,
  },
})
</script>
