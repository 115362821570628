<template>
  <div
    class="p-4 sm:p-6 xl:p-10 mb-6 max-w-screen-2xl m-auto bg-madera-100 mt-4 sm:mt-8 lg:mt-12"
  >
    <TranslationReferenceSwitcher
      :model-value="selectedTranslationId"
      :translation-references="translationReferenceOptions"
      class="mb-2 sm:mb-4"
      @update:model-value="updateSelectedTranslation"
    />

    <TranslationReference :translation-piece="selectedTranslation" />
  </div>
</template>

<script>
import TranslationReference from './TranslationReference.vue'
import TranslationReferenceSwitcher from './TranslationReferenceSwitcher.vue'

import { computed, ref, toRef } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { useRouter } from 'vue-router'

export default {
  components: {
    TranslationReference,
    TranslationReferenceSwitcher,
  },
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const blokRef = toRef(props, 'blok')
    const translationReferences = ref(blokRef.value.translation_reference)

    // The id to v-model
    const selectedTranslationId = ref(
      translationReferences.value[0].content._uid
    )

    const selectedTranslation = computed(() => {
      return translationReferences.value.find(
        (el) => el.content._uid === selectedTranslationId.value
      )
    })

    // Create the options for the translation reference switcher
    const translationReferenceOptions = computed(() => {
      return translationReferences.value.map((el) => {
        return {
          label: el.content.title,
          value: el.content._uid,
          slug: el.slug,
        }
      })
    })

    // Update the route with a new query when the select changes
    const router = useRouter()
    const addQuery = () => {
      router.push({
        name: router.currentRoute.value.name,
        query: { piece: selectedTranslation.value.slug, page: 1 },
      })
    }

    // When the select chooses a new piece to show, update the piece and the route
    const updateSelectedTranslation = (translationId) => {
      selectedTranslationId.value = translationId

      addQuery()
    }

    // When the route changes, check to see if we should show a different translation reference.
    // When someone clicks the back button, they'll see the piece they were looking at before.
    onBeforeRouteUpdate(async (to, from) => {
      if (!to.query.piece) {
        selectedTranslationId.value =
          translationReferences.value[0].content._uid
      } else if (to.query.piece !== from.query.piece) {
        selectedTranslationId.value = translationReferences.value.find(
          (el) => el.slug === to.query.piece
        ).content._uid
      }
    })

    // The first time the component is loaded, check if there's a query in the route already.
    // If so, show that instead of our default first translation piece.
    const firstQuery = () => {
      const idFromRoute = translationReferences.value.find(
        (el) => el.slug === router.currentRoute.value.query.piece
      )

      // If there's no query in the URL, or if the query in the URL is wrong, use the original ref values
      if (!router.currentRoute.value.query.piece || idFromRoute === undefined) {
        router.replace({
          name: router.currentRoute.value.name,
          query: { piece: translationReferences.value[0].slug, page: 1 },
        })
        return
      }

      selectedTranslationId.value = translationReferences.value.find(
        (el) => el.slug === router.currentRoute.value.query.piece
      ).content._uid
    }
    firstQuery()

    return {
      selectedTranslation,
      selectedTranslationId,
      translationReferenceOptions,
      updateSelectedTranslation,
    }
  },
}
</script>
