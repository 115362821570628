<template>
  <li>
    <router-link
      v-if="isLink"
      class="inline-flex justify-center items-center pwc-button py-2 px-6 min-h-11"
      v-bind="$attrs"
      :to="{
        name: router.currentRoute.value.name,
        query: { ...router.currentRoute.value.query, page: page },
      }"
    >
      <span class="sr-only">
        {{ global.settings.translationPaginationGoTo }}
      </span>
      <slot />
    </router-link>

    <p
      v-else
      class="inline-flex items-center justify-center p-2 px-6 text-gray-600 pwc-button"
      v-bind="$attrs"
    >
      <slot />
    </p>
  </li>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStoryblokStore } from '../store/useStoryblok'

export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()
    const global = useStoryblokStore()

    return {
      router,
      global,
    }
  },
}
</script>
