<template>
  <header v-if="!loading">
    <LogoBar />
    <PrimaryNav class="hidden lg:block" />
    <PrimaryNavMobile class="lg:hidden" />
  </header>

  <main id="main" tabindex="-1">
    <router-view />
  </main>
</template>

<script setup>
import { ref, watch } from 'vue'
import LogoBar from './components/LogoBar.vue'
import PrimaryNav from './components/PrimaryNav.vue'
import PrimaryNavMobile from './components/PrimaryNavMobile.vue'

import { useStoryblokStore } from './store/useStoryblok'
import { useRoute } from 'vue-router'

const route = useRoute()

const global = useStoryblokStore()

const loading = ref(false)
const getSettingsFromStore = async (locale) => {
  loading.value = true

  await global.getGlobalSettings(locale)

  loading.value = false
}

// Reload global settings when the locale changes
watch(
  () => route.params.locale,
  (newLocale) => {
    if (newLocale === undefined) return
    getSettingsFromStore(newLocale)
  },
  { immediate: true }
)
</script>
