<template>
  <nav :aria-label="global.settings.translationPaginationNavLabel">
    <ul class="flex items-start sm:items-center justify-between flex-wrap mt-6">
      <div class="flex flex-col items-start sm:items-center sm:flex-row gap-6">
        <TranslationReferencePaginationLink
          :is-link="currentPageNum > 1"
          :page="1"
          :class="currentPageNum > 1 ? '' : 'text-gris-400'"
        >
          <BaseIcon icon="fast_rewind" class="mr-2" />
          {{ global.settings.translationPaginationFirstPage }}
        </TranslationReferencePaginationLink>

        <TranslationReferencePaginationLink
          :is-link="currentPageNum > 1"
          :page="previousPage"
          class="rounded-sm"
          :class="currentPageNum > 1 ? 'bg-madera-300 shadow' : 'bg-gray'"
        >
          <BaseIcon icon="arrow_left" />
          {{ global.settings.translationPaginationPreviousPage }}
        </TranslationReferencePaginationLink>
      </div>

      <p class="p-2">
        {{ global.settings.translationPaginationPageWord }}
        {{ currentPageNum }}
        {{ global.settings.translationPaginationOfWord }}
        {{ totalPages }}
      </p>

      <div class="flex flex-col items-start sm:items-center sm:flex-row gap-6">
        <TranslationReferencePaginationLink
          :is-link="currentPageNum < totalPages"
          :page="nextPage"
          class="rounded-sm"
          :class="
            currentPageNum < totalPages ? 'bg-madera-300 shadow' : 'bg-gray'
          "
        >
          {{ global.settings.translationPaginationNextPage }}
          <BaseIcon icon="arrow_right" />
        </TranslationReferencePaginationLink>

        <TranslationReferencePaginationLink
          :is-link="currentPageNum < totalPages"
          :page="totalPages"
          :class="currentPageNum < totalPages ? '' : 'text-gris-400'"
        >
          {{ global.settings.translationPaginationLastPage }}
          <BaseIcon icon="fast_forward" class="ml-2" />
        </TranslationReferencePaginationLink>
      </div>
    </ul>
  </nav>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import TranslationReferencePaginationLink from './TranslationReferencePaginationLink.vue'
import BaseIcon from './BaseIcon.vue'

import { useStoryblokStore } from '../store/useStoryblok'

export default {
  components: {
    TranslationReferencePaginationLink,
    BaseIcon,
  },
  props: {
    totalPages: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const router = useRouter()
    const currentPageNum = computed(() => {
      return router.currentRoute.value.query.page
    })

    const nextPage = computed(() => {
      return parseInt(currentPageNum.value) + 1
    })

    const previousPage = computed(() => {
      return parseInt(currentPageNum.value) - 1
    })

    const global = useStoryblokStore()

    return {
      router,
      nextPage,
      previousPage,
      currentPageNum,
      global,
    }
  },
}
</script>
