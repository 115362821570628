import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useStoryblokApi } from '@storyblok/vue'

// Optional page name for hardcoded route
export default function (pagePath) {
  const route = useRoute()
  const router = useRouter()

  const storyblokApi = useStoryblokApi()
  const loading = ref(false)
  const story = ref({})
  const pageTitle = ref('')

  const loadStory = async () => {
    loading.value = true

    try {
      const res = await storyblokApi.get(
        `cdn/stories/${pagePath || route.name || route.params.slug || 'home'}`,
        {
          version: process.env.VUE_APP_STORYBLOK_PUBLISHED_STATUS,
          language: route.params.locale,
          resolve_relations: 'translation-embed.translation_reference',
        }
      )
      story.value = res.data.story
      pageTitle.value =
        res.data.story.content.title || 'Precision Wordcraft LLC'
    } catch (e) {
      console.warn('an error occurred')
      router.push({ path: 'error' })
    } finally {
      loading.value = false
    }
  }

  watch(
    () => route.params,
    (newRoute, oldRoute) => {
      if (!oldRoute) {
        loadStory()
      } else if (oldRoute.locale !== newRoute.locale) {
        loadStory()
      } else if (oldRoute.slug !== newRoute.slug) {
        loadStory()
      }
    },
    { immediate: true }
  )

  return {
    loading,
    story,
    loadStory,
    pageTitle,
  }
}
