<template>
  <div>
    <a class="sr-only focus:not-sr-only text-3xl" href="#main">
      {{ global.settings.navSkipLink }}
    </a>
    <div class="sm:hidden flex items-center justify-center p-2">
      <a href="/" class="flex items-center">
        <img class="h-10" src="../assets/images/pwcLogo.svg" alt />
      </a>
    </div>
    <div
      class="hidden sm:flex items-center justify-between sm:py-6 sm:px-4 lg:px-8 2xl:px-10"
    >
      <div class="flex-grow flex">
        <a href="/" class="flex items-center">
          <img
            class="logo__image mr-2"
            src="../assets/images/pwcLogo.svg"
            alt
          />

          <span
            class="logo__sitename flex flex-col text-right break-all hyphens-auto"
          >
            <span>
              {{ global.settings.siteNameFirst }}
            </span>
            <span>
              {{ global.settings.siteNameLast }}
            </span>
          </span>

          <span class="sr-only">{{ global.settings.navHome }}</span>
        </a>
      </div>
      <LanguageSwitcher class="inline-block flex-grow-0" />
    </div>
  </div>
</template>

<script setup>
import LanguageSwitcher from './LanguageSwitcher.vue'

import { useStoryblokStore } from '../store/useStoryblok'
const global = useStoryblokStore()
</script>
