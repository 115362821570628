import { defineStore } from 'pinia'
import { useStoryblokApi } from '@storyblok/vue'
import { useRouter } from 'vue-router'

export const useStoryblokStore = defineStore('global', {
  state: () => ({
    settings: {},
  }),
  getters: {
    navMainLinks: (state) => {
      if (!state.settings.navMainLinks) return
      return state.settings.navMainLinks.map((item) => {
        return {
          dest: item.link.cached_url,
          title: item.text,
        }
      })
    },
  },
  actions: {
    async getGlobalSettings(lang) {
      const router = useRouter()

      const storyblokApi = useStoryblokApi()
      try {
        const { data } = await storyblokApi.get(`cdn/stories/global/global`, {
          version: process.env.VUE_APP_STORYBLOK_PUBLISHED_STATUS,
          language: lang, //route.params.locale
          resolve_relations: 'translation-embed.translation_reference',
        })
        this.settings = data.story.content
      } catch (e) {
        console.warn('an error occurred')
        router.push({ path: 'error' })
      }
    },
  },
})
