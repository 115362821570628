/* eslint-disable no-unused-vars */
import { createApp } from 'vue'

// Storyblok, components, etc
import { StoryblokVue, apiPlugin } from '@storyblok/vue'

import AboutMe from './components/AboutMe.vue'
import ContactMe from './components/ContactMe.vue'
import CardGrid from './components/CardGrid.vue'
import TestimonialGrid from './components/TestimonialGrid.vue'
import CardRow from './components/CardRow.vue'
import CardItem from './components/CardItem.vue'
import CardTestimonial from './components/CardTestimonial.vue'
import Page from './components/Page.vue'
import Loading from './components/Loading.vue'
import PrimaryIntroduction from './components/PrimaryIntroduction.vue'
import SecondaryIntroduction from './components/SecondaryIntroduction.vue'
import TranslationEmbed from './components/TranslationEmbed.vue'
import CtaLink from './components/CtaLink.vue'

// Base components
import BaseIcon from './components/BaseIcon.vue'
import BaseLink from './components/BaseLink.vue'

import App from './App.vue'

// i18n
import { setupRouter } from './router.js'
import { setupI18n, FALLBACK_LANGUAGE, initialLocale } from './i18n'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'

// Styles
import 'windi.css'
import './assets/styles/custom.css'
import { createPinia } from 'pinia'

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: initialLocale(),
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: {
    en,
    es,
    fr,
  },
})
const router = setupRouter(i18n)

const app = createApp(App)

app.component('BaseIcon', BaseIcon)
app.component('BaseLink', BaseLink)
app.component('AboutMe', AboutMe)
app.component('CardGrid', CardGrid)
app.component('CardItem', CardItem)
app.component('CardRow', CardRow)
app.component('CardTestimonial', CardTestimonial)
app.component('ContactMe', ContactMe)
app.component('CtaLink', CtaLink)
app.component('Page', Page)
app.component('Loading', Loading)
app.component('PrimaryIntroduction', PrimaryIntroduction)
app.component('SecondaryIntroduction', SecondaryIntroduction)
app.component('TestimonialGrid', TestimonialGrid)
app.component('TranslationEmbed', TranslationEmbed)

app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(StoryblokVue, {
  accessToken: process.env.VUE_APP_STORYBLOK_API_TOKEN,
  use: [apiPlugin],
})
app.mount('#app')
