<template>
  <div>
    <AccordionDetails
      :button-title="global.settings.translationCopyrightLabel"
      class="mt-4"
    >
      <div
        class="parsed"
        v-html="parse(translationPiece.content.copyright)"
      ></div>
    </AccordionDetails>

    <h2 class="sm:text-center text-3xl sm:text-6xl mt-4 sm:mt-6">
      {{ translationPiece.content.title }}
    </h2>
    <p class="sm:text-center text-xl sm:text-3xl mt-1 sm:mt-4 mb-4 sm:mb-5">
      {{ translationPiece.content.author }}
    </p>

    <TranslationReferencePanel :panel="panelToShow" />

    <TranslationReferencePagination :total-pages="totalPages" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, toRef } from 'vue'
import { parse } from 'marked'

import TranslationReferencePagination from './TranslationReferencePagination.vue'
import TranslationReferencePanel from './TranslationReferencePanel.vue'
import AccordionDetails from './AccordionDetails.vue'

import { useStoryblokStore } from '../store/useStoryblok'

export default {
  components: {
    TranslationReferencePagination,
    TranslationReferencePanel,
    AccordionDetails,
  },
  props: {
    // One Translation Piece story from the API
    translationPiece: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute()

    const translationPiece = toRef(props, 'translationPiece')

    const panelsArray = computed(() => {
      return translationPiece.value.content.translation_panels
    })

    const totalPages = computed(() => {
      return panelsArray.value.length
    })

    const pageNum = () => {
      // If for some reason the URL has no page
      if (!route.query.page) {
        return 0
      }

      // If there is only one panel
      if (route.query.page - 1 <= 0) {
        return 0
      }

      // If the page number in the URL goes out of bounds, show us the last page
      if (route.query.page - 1 >= totalPages.value) {
        return totalPages.value - 1
      }

      return route.query.page - 1
    }

    const panelToShow = computed(() => {
      return panelsArray.value[pageNum()]
    })

    const global = useStoryblokStore()

    return {
      panelToShow,
      totalPages,
      global,
      parse,
    }
  },
}
</script>
