import { createRouter, createWebHistory } from 'vue-router'
import {
  setI18nLanguage,
  loadLocaleMessages,
  SUPPORT_LOCALES,
  initialLocale,
} from './i18n'

import BasePage from './pages/BasePage.vue'
import ErrorPage from './pages/ErrorPage.vue'
import StyleGuide from './pages/StyleGuide.vue'

export function setupRouter(i18n) {
  const locale = initialLocale()

  // setup routes
  const routes = [
    {
      path: '/:locale/styleguide',
      name: 'styleguide',
      component: StyleGuide,
    },
    {
      path: '/styleguide',
      name: 'styleguideBare',
      component: StyleGuide,
      redirect: () => `/${locale}/styleguide`,
    },
    {
      path: '/',
      component: BasePage,
    },
    {
      path: '/:locale/',
      component: BasePage,
    },
    {
      path: '/:locale/:slug',
      component: BasePage,
    },
    {
      path: '/:locale/error',
      name: 'errorPage',
      component: ErrorPage,
    },
  ]

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  // navigation guards

  router.beforeEach(async (to) => {
    const paramsLocale = to.params.locale

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
      // If there is a slug, you might be searching for a language we don't support
      if (to.params.slug) {
        return `/${locale}/${to.params.slug}`
      } else if (to.params.locale) {
        // If you only have one arg on your URL, let's assume you were trying to get to a page but didn't specify a language
        return `/${locale}/${to.params.locale}`
      }

      // If you've neither a locale nor any path, you belong on the home page
      return `/${locale}`
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale)
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale)
  })

  return router
}
