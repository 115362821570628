<template>
  <div class="px-4 sm:px-6 lg:px-10 xl:px-20 my-4 sm:my-12">
    <!-- Header info -->
    <h1>{{ blok.title }}</h1>
    <div class="parsed mt-2" v-html="parse(blok.description)"></div>

    <!-- Optional intro content -->
    <div v-if="blok.horizontallinks.length" class="pt-6">
      <HorizontalLinks
        v-if="blok.horizontallinks"
        :links="blok.horizontallinks"
      />
    </div>

    <div v-if="blok.expandeddescription">
      <AccordionDetails :button-title="global.settings.buttonsLearnMore">
        <div class="parsed" v-html="parse(blok.expandeddescription)"></div>
      </AccordionDetails>
    </div>
  </div>

  <!-- Banner -->
  <div v-if="blok.bannerimage.length" class="aspect-5/16">
    <img
      class="w-full object-cover"
      :src="bannerImage.src"
      :alt="bannerImage.alt"
    />
  </div>

  <p
    v-if="blok.bannerimage.length && bannerImage.attribution"
    class="text-right text-sm"
  >
    {{ bannerImage.attribution }}
  </p>
</template>

<script setup>
import { computed } from 'vue'
import HorizontalLinks from './HorizontalLinks.vue'
import AccordionDetails from './AccordionDetails.vue'

import { parse } from 'marked'

import { useStoryblokStore } from '../store/useStoryblok'
const global = useStoryblokStore()

const props = defineProps({
  blok: {
    type: Object,
    default: () => {},
  },
})

const bannerImage = computed(() => {
  const img = props.blok.bannerimage[0].image
  const attrib = props.blok.bannerimage[0].attribution

  return {
    src: img.filename,
    alt: img.alt,
    attribution: attrib,
  }
})
</script>
