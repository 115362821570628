<template>
  <footer class="mt-3 py-3 sm:pb-6 sm:pt-12 px-4 bg-madera-100">
    <div class="flex justify-center items-center h-25">
      <a
        href="https://www.linkedin.com/company/precision-wordcraft"
        class="inline-flex h-11"
      >
        <img src="../assets/images/linkedin.svg" alt="LinkedIn" />
      </a>
    </div>

    <p
      class="flex flex-wrap justify-center items-center text-center mt-3 sm:mt-6 mx-4"
    >
      <BaseIcon icon="copyright" class="pr-1 w-6 h-6 text-black" />
      <span class="sr-only">{{ global.settings.footerCopyrightWord }} </span>
      <span>{{ global.settings.footerCopyrightInfo }}</span>
    </p>

    <div class="mx-4 mt-8 sm:mt-16 mb-3 sm:mb-6 border-t border-t-black" />

    <div
      class="mx-4 text-right parsed"
      v-html="parse(global.settings.footerDesignCredit)"
    ></div>
  </footer>
</template>

<script setup>
import { useStoryblokStore } from '../store/useStoryblok'
import { parse } from 'marked'

const global = useStoryblokStore()
</script>
