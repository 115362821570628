<template>
  <BaseModal
    :is-modal-open="isModalOpen"
    :title="global.settings.switcherChooseLanguage"
    @close="closeModal"
    @open="isModalOpen = true"
  >
    <template #beforeTitle>
      <BaseIcon icon="language" />
    </template>
    <template #content>
      <div>
        <ul>
          <li
            v-for="lang in allLanguages"
            :key="lang.shortcode"
            class="text-center block mb-3"
          >
            <BaseLink
              :to="{
                name: router.currentRoute.value.name,
                params: { locale: lang.shortcode },
              }"
              class="text-pwcLinkBlue underline font-bold text-lg tracking-wide"
              @click="changeDefaultLanguage(lang.shortcode)"
            >
              {{ lang.name }}
            </BaseLink>
          </li>
        </ul>
        <div
          class="mt-6 flex flex-col gap-6"
          v-html="parse(global.settings.switcherHelpText)"
        ></div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue'

import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import { parse } from 'marked'
import { useI18n } from 'vue-i18n'
import { SITE_LANGUAGES } from '@/i18n'

import { useStoryblokStore } from '../store/useStoryblok'
const global = useStoryblokStore()

const router = useRouter()
const { locale } = useI18n({ useScope: 'global' })

const emit = defineEmits(['close:languageSwitcher'])

// Language modal
const isModalOpen = ref(false)

const closeModal = () => {
  isModalOpen.value = false
  emit('close:languageSwitcher')
}

/**
 * select locale value for language select form
 *
 * If you use the vue-i18n composer `locale` property directly, it will be re-rendering component when this property is changed,
 * before dynamic import was used to asynchronously load and apply locale messages
 * To avoid this, use the another locale reactive value.
 */
const currentLocale = ref(locale.value)
const allLanguages = computed(() => {
  return SITE_LANGUAGES
})

/**
 * when we change the locale, go to locale route
 *
 * when the changes are detected:
 * 1. Load the locale message and set the language via vue-router navigation guard.
 * 2. Change the vue-i18n locale.
 * 3. Change the localstorage, which we want to use as our first preference for language.
 */
const changeDefaultLanguage = (lang) => {
  localStorage.setItem('languagecode', lang)
  currentLocale.value = lang

  router.push({
    name: router.currentRoute.value.name,
    params: { locale: lang },
    query: router.currentRoute.value.query,
  })

  closeModal()
}
</script>
