<template>
  <div v-bind="$attrs">
    <slot name="trigger">
      <IconButton icon="language" @click.prevent="openModal">
        <template #afterIcon>
          {{ global.settings.switcherChooseLanguage }}</template
        >
      </IconButton>
    </slot>
  </div>

  <UseFocusTrap v-if="isModalOpen" @keydown.esc="closeModal">
    <div class="bg-black opacity-25 fixed inset-0" />
    <div class="fixed inset-0 z-10 flex items-center justify-center">
      <div
        ref="target"
        :title="title"
        class="max-h-screen overflow-y-auto max-w-119 bg-madera-100 shadow"
        role="dialog"
        aria-modal="true"
      >
        <main class="flex flex-col p-6">
          <div class="flex justify-between items-center">
            <h2 id="modalTitle" class="h3 flex-grow text-center">
              <slot name="beforeTitle" />
              {{ title }}
            </h2>
            <IconButton
              :aria-label="global.settings.buttonsClose"
              icon="close"
              class="flex-grow-0"
              @click="closeModal"
            />
          </div>
          <div class="mt-6">
            <slot name="content" />
          </div>
        </main>
      </div>
    </div>
  </UseFocusTrap>
</template>

<script setup>
import { ref, watch } from 'vue'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { onClickOutside } from '@vueuse/core'

import IconButton from './IconButton.vue'

import { useStoryblokStore } from '../store/useStoryblok'
const global = useStoryblokStore()

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
})

watch(
  () => props.isModalOpen,
  (newVal) => {
    // The modal is open, so add the modal active class to disallow scrolling
    if (newVal === true) {
      if (!document.body.classList.contains('modal--active')) {
        document.body.classList.add('modal--active')
      }
    } else {
      if (document.body.classList.contains('modal--active')) {
        document.body.classList.remove('modal--active')
      }
    }
  }
)

const emit = defineEmits(['close', 'open'])
const closeModal = () => {
  emit('close')
}
const openModal = () => {
  emit('open')
}

const target = ref(null)
onClickOutside(target, () => closeModal())
</script>
