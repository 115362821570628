<template>
  <h3 v-bind="$attrs">
    <button
      id="accordionButton"
      type="button"
      :aria-expanded="isExpanded"
      aria-controls="detailsSection"
      @click="isExpanded = !isExpanded"
    >
      <span class="flex text-left items-center button py-2">
        <span class="hover:underline">
          {{ buttonTitle }}
          <BaseIcon
            class="ml-2 text-xs font-bold"
            :icon="isExpanded ? 'remove' : 'add'"
          />
        </span>
      </span>
    </button>
  </h3>

  <div
    v-if="isExpanded"
    id="detailsSection"
    role="region"
    aria-labelledby="accordionButton"
    class="my-2"
  >
    <slot />
  </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue'

import { ref } from 'vue'

export default {
  components: { BaseIcon },
  inheritAttrs: false,
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isExpanded = ref(false)

    return {
      isExpanded,
    }
  },
}
</script>
