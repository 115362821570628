import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

export const SUPPORT_LOCALES = ['en', 'es', 'fr']
export const FALLBACK_LANGUAGE = 'en'
export const SITE_LANGUAGES = [
  {
    shortcode: 'en',
    name: 'English',
  },
  {
    shortcode: 'es',
    name: 'español',
  },
  {
    shortcode: 'fr',
    name: 'français',
  },
]

// For use if the user visits for the first time with a language specified in the URL
const firstPathSegment = window.location.pathname.split('/')[1]

export const initialLocale = () => {
  if (SUPPORT_LOCALES.includes(localStorage.languagecode)) {
    return localStorage.languagecode
  } else if (SUPPORT_LOCALES.includes(firstPathSegment) === true) {
    return firstPathSegment
  } else {
    return FALLBACK_LANGUAGE
  }
}

export function setupI18n(options = { locale: initialLocale }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)

  // If there's no languagecode in localstorage, set one now
  if (!localStorage.languagecode) {
    localStorage.setItem('languagecode', initialLocale())
  }
}

export async function loadLocaleMessages(i18n, locale) {
  // We load most translations directly from Storyblok, but some translations are hardcoded
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}
