<template>
  <a v-if="isExternal" :class="{ externalLink: isExternal }" :href="to">
    <slot />
  </a>
  <router-link v-else v-bind="$props">
    <slot />
  </router-link>
</template>

<script setup>
import { defineProps, computed } from 'vue'

import { RouterLink } from 'vue-router'

const props = defineProps({ ...RouterLink.props })

const isExternal = computed(() => {
  return (
    typeof props.to === 'string' &&
    (props.to.startsWith('http') || props.to.startsWith('www.'))
  )
})
</script>
