<template>
  <div class="px-4 sm:px-6 lg:px-10 xl:px-20">
    <component
      :is="oneBlok.component"
      v-for="oneBlok in blok.body"
      :key="oneBlok._uid"
      :blok="oneBlok"
    />
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
