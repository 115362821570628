<template>
  <BaseModal
    :is-modal-open="isModalOpen"
    :title="global.settings.navMainMenu"
    class="bg-madera-100 px-4 flex justify-between items-center w-full"
    @close="closeModal"
  >
    <template #trigger>
      <IconButton
        icon="menu"
        :aria-label="global.settings.navMainMenu"
        @click.prevent="openModal"
      />
      <a
        href="https://www.linkedin.com/company/precision-wordcraft"
        class="inline-flex h-11 p-4"
      >
        <img src="../assets/images/linkedin.svg" alt="LinkedIn" />
      </a>
    </template>

    <template #content>
      <nav
        id="primaryNav"
        class="mx-auto flex justify-center items-center min-h-11 px-8 xl:px-10 2xl:px-10"
      >
        <ul
          v-if="navMainLinks && isModalOpen"
          class="flex flex-col gap-6 2xl:gap-8 items-center"
        >
          <li
            v-for="link in navMainLinks"
            :key="link.dest"
            class="block text-center"
          >
            <!-- Workaround: The component isn't destroyed between pages, so close the modal manually -->
            <BaseLink
              class="hover:underline hover:font-bold text-lg p-2 text-center"
              :to="link.dest"
              @click="closeModal"
            >
              {{ link.title }}
            </BaseLink>
          </li>
          <li>
            <LanguageSwitcher
              class="sm:hidden"
              @close:language-switcher="closeModal"
            />
          </li>
        </ul>
      </nav>
    </template>
  </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue'
import LanguageSwitcher from './LanguageSwitcher.vue'
import IconButton from './IconButton.vue'

import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

import { storeToRefs } from 'pinia'
import { useStoryblokStore } from '../store/useStoryblok'

const global = useStoryblokStore()

const { navMainLinks } = storeToRefs(global)

const target = ref(null)
onClickOutside(target, () => closeModal())

const isModalOpen = ref(false)

const closeModal = () => {
  isModalOpen.value = false
  if (document.body.classList.contains('modal--active')) {
    document.body.classList.remove('modal--active')
  }
}
const openModal = () => {
  isModalOpen.value = true
  if (!document.body.classList.contains('modal--active')) {
    document.body.classList.add('modal--active')
  }
}
</script>
