<template>
  <div>
    <Loading v-if="loading" />
    <div v-else-if="story.content && story.content.component">
      <PageHeader
        v-if="
          story.content && story.content.component && story.content.pageheader
        "
        :key="story.content._uid"
        :page-header-bloks="story.content.pageheader"
      />

      <Page
        v-if="story.content && story.content.component"
        :key="story.content._uid"
        :blok="story.content"
      />

      <PageFooter />
    </div>
    <div v-else>
      <h1>{{ $t('errorPage.title') }}</h1>
      <router-link to="home">{{ $t('errorPage.linkText') }}</router-link>
    </div>
  </div>
</template>

<script setup>
import StoryblokComposable from '../composables/StoryblokComposable'
import { useTitle } from '@vueuse/core'

import PageHeader from '../components/PageHeader.vue'
import PageFooter from '../components/PageFooter.vue'

const { loading, story, pageTitle } = StoryblokComposable()

useTitle(pageTitle)
</script>
