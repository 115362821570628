<template>
  <div>
    <BaseSelect
      :opts="translationReferences"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
    >
      <template #label>{{ global.settings.translationSwitcherLabel }}</template>
    </BaseSelect>
  </div>
</template>

<script setup>
import BaseSelect from './BaseSelect.vue'

import { useStoryblokStore } from '../store/useStoryblok'
const global = useStoryblokStore()

defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  translationReferences: {
    type: Array,
    default: () => [],
  },
})

defineEmits(['update:modelValue'])
</script>
